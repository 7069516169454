const idcard = {}


idcard.hasError = function (IdCardNO) {
    let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (reg.test(IdCardNO) === false) {
        return "身份证格式不正确";
    }
    const province = {
        11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古",
        21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏",
        33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南",
        42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆",
        51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃",
        63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外"
    };
    if (province[parseInt(IdCardNO.substr(0, 2))] == null) return "身份证格式不正确"; //"Error:非法地区";
    let sBirthday = IdCardNO.substr(6, 4) + "-" + Number(IdCardNO.substr(10, 2)) + "-" + Number(IdCardNO.substr(12, 2));
    let d = new Date(sBirthday.replace(/-/g, "/"))
    if (sBirthday != (d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate())) return "身份证格式不正确"; //"Error:非法生日";
    let iSum = 0
    IdCardNO = IdCardNO.replace(/x$/i, "a");
    for (let i = 17; i >= 0; i--) iSum += (Math.pow(2, i) % 11) * parseInt(IdCardNO.charAt(17 - i), 11)
    if (iSum % 11 != 1) return "身份证格式不正确"; //"Error:非法证号";
    return
}

idcard.getAge = function (IdCardNO) {
    if (IdCardNO.length == 18)//18位身份证号处理
    {
        let yearBirth = IdCardNO.substring(6, 10)
        let monthBirth = IdCardNO.substring(10, 12);
        let dayBirth = IdCardNO.substring(12, 14);
        // 获取当前年月日并计算年龄
        let myDate = new Date();
        let monthNow = myDate.getMonth() + 1;
        let dayNow = myDate.getDate();
        let age = myDate.getFullYear() - yearBirth;
        if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
            age--;
        }
        return age
    }
    return ''
}

idcard.getGender = function (IdCardNO) {
    if (IdCardNO.length == 18)//18位身份证号处理
    {
        // 获取性别
        if (parseInt(IdCardNO.substr(16, 1)) % 2 == 1) {
            return '男'
        } else {
            return '女'
        }
    }
    return ''
}

export default idcard
